body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Nunito", sans-serif !important;
  padding: 0 !important;
  background-color: rgb(239, 243, 246) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.pac-container {
  z-index: 9999;
}
.rdrMonth {
  max-width: 300 !important;
}

.rdrEndEdge {
  color: #0635c9 !important;
}

.rdrStartEdge {
  color: #0635c9 !important;
}
.rdrInRange {
  color: rgb(211, 248, 255) !important;
}

.rdrInRange + .rdrDayNumber span {
  color: #000 !important;
}

.makeStyles-tableContainer-382 {
  padding: 0 !important;
}

.MuiPaper-root,
.MuiTableContainer-root,
.makeStyles-tableContainer-890,
.MuiPaper-elevation1,
.MuiPaper-rounded {
  padding: 0 !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background: none !important;
}

.rdrInRange + span {
  color: #0635c9 !important;
}

.rdrDayHovered {
  color: #000 !important;
  border: none !important;
}

.rdrDayStartPreview,
.rdrDayEndPreview,
.rdrDayinPreview {
  color: #000 !important;
  border: none !important;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
  color: #000 !important;
}

.rdrDayHovered {
  color: #0635c9 !important;
}
/* .rdrStartEdge{
      background: rgb(211, 248, 255) !important;;
      z-index: 0;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    
    .rdrStartEdge + .rdrDayNumber {
      background: #0635C9!important;
      z-index: 1;
      border-radius: 10px;
    } */

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiChip-root {
  background-color: #fff !important;
  border: 1px solid #e0e0e0 !important;
}

.slick-dots {
  bottom: 20px !important;
}

/* .slick-dots li button {
      width: 30px !important;
      height: 30px !important;
    } */

.slick-dots li button:before {
  font-size: 14px !important;
}

.slick-dots li.slick-active button:before {
  color: #7f8fa4 !important;
}

.slick-dots li button:before {
  color: #7f8fa4 !important;
}

.MuiButton-root.MuiButton-root {
  text-transform: none;
}

.rdrPprevButton {
  background: #fff !important;
}
.rdrPprevButton i {
  border-right-color: rgb(132, 144, 149) !important;
}
.rdrNextPrevButton {
  background: #fff !important;
}
.rdrNextButton i {
  border-left-color: rgb(132, 144, 149) !important;
}

.MuiDialogContent-root:first-child {
  padding-top: 0 !important;
}

/* /////////////////////////// */
/* .rdrStartEdge, .rdrEndEdge{
      border-radius: 1.042em !important;
    } */
/* /////////////////////////// */
